import * as React from "react"
import Image from "../components/image"
import { Link } from "gatsby"
import "../assets/sass/index.sass"
import hardScapingIcon from "../assets/icons/brickwall.svg"
import lawnicon from "../assets/icons/lawn.svg"
import manShovelingSnowIcon from "../assets/icons/man-snow.svg"
import commercialIcon from "../assets/icons/river.svg"

import Carousel from 'react-bootstrap/Carousel'
import 'bootstrap/dist/css/bootstrap.min.css'

import yelpLogo from "../assets/icons/yelp-logo.png"
import googleLogo from "../assets/icons/google.svg"

const landscapeBushes = "https://firebasestorage.googleapis.com/v0/b/kevins-fencing.appspot.com/o/PicsArt_06-22-10.08.28-min.jpg?alt=media&token=892d0da8-9d58-421f-9722-ab81f5225935"
const landscapeBushes2 = "https://firebasestorage.googleapis.com/v0/b/kevins-fencing.appspot.com/o/PicsArt_06-22-11.48.33-min.jpg?alt=media&token=c0aba348-3ee8-42f2-8aed-0385372646d9"
const paverImage = "https://firebasestorage.googleapis.com/v0/b/kevins-fencing.appspot.com/o/PicsArt_06-22-09.10.44.jpg?alt=media&token=aa16046c-c013-4954-9ae0-349b498dcbea"
const paverSideWalkImage = "https://firebasestorage.googleapis.com/v0/b/kevins-fencing.appspot.com/o/PicsArt_06-22-09.11.08.jpg?alt=media&token=c8a94bb7-e7b0-4e1b-9bf8-3a878170ca56"
const imageThree = "https://firebasestorage.googleapis.com/v0/b/kevins-fencing.appspot.com/o/PicsArt_06-22-09.15.49.jpg?alt=media&token=deb8db8b-77a4-4399-b4ca-c61b4e2cfe94"

const IndexPage = () => {
  return (
    <div className="main">

      <section className="main-hub">
      
        <div className="slideshow">
            <div className="animated-intro">
                <p className="slide-text slide-1">Welcome to Kevin's Landscaping</p>
                <p className="slide-text slide-3">We have Years of Experience and new Equipment</p>
                <p className="slide-text slide-2">We're Licenced and fully insured.</p>
                <p className="slide-text slide-4">We Accept Credit Cards</p>
            </div>
            <Carousel>
                <Carousel.Item interval={8000}>
                    <div>                    
                        <Image filename="frontyard-mulch.jpg"/>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={2500}>
                    <div>
                        <img src={landscapeBushes}/>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={2500}>
                    <div>                    
                        <img src={imageThree}/>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>

        <div className="home-display-hub">
          <div className="service">
              <Link to="/services/landscaping">
                  <img src={lawnicon} height="90px" alt="fence icon"/>
                  <p>Landscaping</p>
              </Link>
          </div>
          <div className="service">
              <Link to="/services/hardscaping">
                  <img src={hardScapingIcon} height="90px" alt="fence icon"/>
                  <p>Hardscaping</p>
              </Link>
          </div>
          <div className="service">
              <Link to="/services/snowplowing">
                  <img src={manShovelingSnowIcon} height="90px" alt="fence icon"/>
                  <p>Snow plowing and salting</p>
              </Link>
          </div>
          <div className="service">
              <Link to="/services/commercial">
                  <img src={commercialIcon} height="90px" alt="fence icon"/>
                  <p>Commercial</p>
              </Link>
          </div>
        </div>
      </section>

      <section className="planting-landing-section">
        <div className="planting-landing-title">
            <h1>Let's Start your <span>Garden</span></h1>
            <Image filename="flower-garden.jpeg"/>
        </div>
        <div className="planting-landing-statement">
            <p>Get your garden or new yard looking amazing for the summer season. Kevin's Landscaping has years of plant experties.</p>
            <Link to="/planting">Read More</Link>            
        </div>
      </section>

      <section className="social-medias">

        <div className="social-media-intro">
            <h2>Review us on Yelp or Google</h2>
            <p>
            Your feedback means a lot to us, and it help us do better work. 
            Gives us a review or tell a friend! Thank you.
            </p>
        </div>

        <div className="social-media-icons">
          <a href="https://www.yelp.com/biz/kevins-landscaping-naperville?osq=kevins+landcaping">
            <img src={yelpLogo}/>
          </a>
        </div>

        <div className="social-media-icons">
          <a href="https://g.co/kgs/QUjCWC">
            <img src={googleLogo}/>
          </a>
        </div>

      </section>
      <section className="who-we-are">

        <h2>Kevins Landscaping</h2>

        <div className="slide-show-text slide-show-text-hide">
            <h1>Pavers</h1>
            <p>We handle the entire process from purchasing and fabricating materials for installation of all kinds of Pavers</p>
            <Link to="/pavers">Read More</Link>
        </div>

        <div className="preview-images">
            <Carousel>
                <Carousel.Item interval={2500}>
                    <div>
                        <img src={paverImage}/>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={2500}>
                    <div>                    
                        <img src={imageThree}/>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={2500}>
                    <div>                    
                        <img src={paverSideWalkImage}/>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>

        <div className="slide-show-text slide-show-text-mobile">
                <h1>Pavers</h1>
                <p>We handle the entire process from purchasing and fabricating materials for installation of all kinds of Pavers</p>
            <Link to="/pavers">Read More</Link>
        </div>
            
        <div className="preview-images">
            <Carousel>
                <Carousel.Item interval={2500}>
                    <div>
                        <img src={landscapeBushes}/>
                    </div>
                </Carousel.Item>
                <Carousel.Item interval={2500}>
                    <div>                    
                        <img src={landscapeBushes2}/>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
        
        <div className="slide-show-text">
            <h1>We have 10 years of experience</h1>
        
            <p>We provide various services during Summer, Fall, and Winter.</p>
            <p>We do work in Aurora, Naperville, Plainfield, Joliet, and near the Chicagoland area. </p>
            <Link to="/about">Read More</Link>            
        </div>

      </section>

    </div>
  )
}

export default IndexPage
